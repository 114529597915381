import React, { useState, useEffect } from 'react';
import { ConfigDisplay } from './components/ConfigDisplay';
import { TimerDisplay } from './components/TimerDisplay';
import { Config } from './types';

const DISPLAY_TYPE = {
  CONFIG: 'CONFIG',
  TIMER: 'TIMER',
} as const;
type DISPLAY_TYPE = typeof DISPLAY_TYPE[keyof typeof DISPLAY_TYPE];

const App: React.FC = () => {
  const defaultConfig: Config = {
    startSecCount: 60,
  };

  const [config, setConfig] = useState(defaultConfig);
  const [count, setCount] = useState(config.startSecCount);
  const [isRunning, setIsRunning] = useState(false);
  const [display, setDisplay] = useState(DISPLAY_TYPE.CONFIG as DISPLAY_TYPE);

  useEffect(() => {
    if (isRunning) {
      const id = setInterval(() => {
        setCount((t) => t - 1);
      }, 100);

      // クリーンアップ
      return () => {
        clearInterval(id);
      };
    }
  }, [isRunning]);

  useEffect(() => {
    if (isRunning && count <= 0) {
      setIsRunning(false);
    }
  }, [isRunning, count]);

  // const reset = () => {
  //   setCount(config.startSecCount * 10);
  //   setIsRunning(false);
  // };

  // const toggleRunning = () => {
  //   setIsRunning(!isRunning);
  // };

  const onStart = () => {
    setCount(config.startSecCount * 10);
    setDisplay(DISPLAY_TYPE.TIMER);
    setIsRunning(true);
  };

  const onIncrementPeriod = () => {
    setCount(config.startSecCount * 10);
  };

  const backToConfig = () => {
    setDisplay(DISPLAY_TYPE.CONFIG);
    setIsRunning(false);
  };

  const selectDisplay = () => {
    switch (display) {
      case DISPLAY_TYPE.CONFIG:
        return (
          <ConfigDisplay
            config={config}
            setConfig={setConfig}
            onStart={onStart}
          />
        );
      case DISPLAY_TYPE.TIMER:
        return (
          <TimerDisplay count={count} onIncrementPeriod={onIncrementPeriod} />
        );
      default:
        return;
    }
  };

  return (
    <>
      {selectDisplay()}
      <div style={{ position: 'fixed' }}>
        <button onClick={backToConfig}>backToConfig</button>
      </div>
    </>
  );
};

export default App;
