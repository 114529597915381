import { ChangeEventHandler, FC, FormEventHandler } from 'react';
import { Config } from '../types';

interface ConfigDisplayProps {
  config: Config;
  setConfig(config: Config): void;
  onStart(): void;
}

export const ConfigDisplay: FC<ConfigDisplayProps> = ({
  config,
  setConfig,
  onStart,
}) => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    onStart();
  };

  const PeriodConfig = () => {
    const onChangeTime = (): ChangeEventHandler<HTMLInputElement> => (e) => {
      const newConfig = {
        ...config,
      };
      newConfig.startSecCount = Number(e.target.value);
      setConfig(newConfig);
    };
    return (
      <form onSubmit={handleSubmit}>
        時間
        <input
          key="second"
          value={config.startSecCount}
          type="number"
          min="0"
          onChange={onChangeTime()}
        />
        秒<button>START</button>
      </form>
    );
  };

  return <div>{PeriodConfig()}</div>;
};
