import { FC } from 'react';

interface TimerDisplayProps {
  count: number;
  onIncrementPeriod(): void;
}

// const countToMinSecText = (c: number) => {
//   const minute = Math.floor(c / 60);
//   const second = c % 60;
//   const pad = (n: number) => String(n).padStart(2, '0');
//   return `${minute}:${pad(second)}`;
// };

export const TimerDisplay: FC<TimerDisplayProps> = ({
  count,
  onIncrementPeriod,
}) => {
  const text = `${Math.floor(count / 10)}.${count % 10}`;
  const overlay = (
    <div
      onClick={onIncrementPeriod}
      style={{
        position: 'fixed',
        inset: 0,
      }}
    ></div>
  );
  return (
    <>
      {overlay}
      <div
        style={{
          fontSize: '30vw',
          fontFamily: "'Play', sans-serif",
          textAlign: 'center',
        }}
      >
        {text}
      </div>
    </>
  );
};
